<script setup lang="ts">
import { useHead, useRuntimeConfig, useDevice, useRouteBaseName, useRoute } from '#imports';

const runtimeConfig = useRuntimeConfig();
const device = useDevice();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();
const requestHeaders = useRequestHeaders();

useHead(() => {
  return {
    meta: [
      {
        name: 'viewport',
        content:
          'width=device-width, initial-scale=1, viewport-fit=cover' + (device.isMobile ? ', maximum-scale=1' : ''),
      },
    ],
  };
});

useServerHead(() => {
  const publicCdnUrl = new URL(runtimeConfig.public.cdnUrl).origin;
  let links = [
    {
      rel: 'dns-prefetch',
      href: runtimeConfig.public.apiBase.replace('/v1', ''),
    },
    {
      rel: 'dns-prefetch',
      href: publicCdnUrl,
    },
    {
      rel: 'preconnect',
      href: publicCdnUrl,
    },

    {
      rel: 'preconnect',
      href: 'https://www.googletagmanager.com',
    },
  ];

  if (runtimeConfig.app.cdnUrl) {
    const appCdnUrl = new URL(runtimeConfig.app.cdnUrl).origin;
    links = links.concat([
      {
        rel: 'dns-prefetch',
        href: appCdnUrl,
      },
      {
        rel: 'preconnect',
        href: appCdnUrl,
      },
    ]);
  }

  const meta = [];

  if (requestHeaders.host !== 'www.airpaz.com' && !device.isWf) {
    meta.push({ name: 'robots', content: 'noindex' });
  }

  return {
    link: links,
    meta,
  };
});
</script>

<template>
  <NuxtLayout
    :name="
      getRouteBaseName(route)?.includes('deeplink')
        ? false
        : getRouteBaseName(route)?.includes('aftersales')
        ? 'mobile'
        : $device.isMobile
        ? 'mobile'
        : 'default'
    "
  >
    <NuxtPage />
    <ApzToast
      :full-width="$device.isMobile"
      :class="[$device.isMobile ? '!top-[72px]' : '!top-[87px]']"
    />
  </NuxtLayout>
</template>
