export default defineAppConfig({
  defaults: {
    language: 'en',
    country: 'MY',
    currency: 'MYR',
    appstoreUrl: '',
    playstoreUrl: '',
    appgalleryUrl: '',

    flight: {
      result: {
        expiryTime: 1500000, // 25 minutes
        livecrawlMonitorDelay: 1000, // 1 second
        sessionWatcherInterval: 10000, // 10 seconds
      },

      search: {
        adult: 1,
        child: 0,
        infant: 0,
        onewayCountries: [],
      },
    },
  },
});
