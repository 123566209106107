import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  function scrollTop() {
    window.scrollTo({ top: 0 });
  }

  nuxtApp.hook('page:finish', () => {
    scrollTop();
  });

  return {
    provide: {
      scrollTop,
    },
  };
});
