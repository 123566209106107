import { onNuxtReady, useConfigStore, useRouter, useRuntimeConfig, useCookie, defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin(() => {
  const runtimeConfig = useRuntimeConfig();

  if (process.dev || !runtimeConfig.public.yandexEnabled || !runtimeConfig.public.yandexId) {
    return;
  }

  const router = useRouter();
  const config = useConfigStore();

  const basePath = window.location.origin;

  function loadYandex() {
    const el = document.getElementById('__nuxt');
    el?.insertAdjacentHTML(
      'afterend',
      `<noscript><div><img src="https://mc.yandex.ru/watch/${runtimeConfig.public.yandexId}" style="position:absolute; left:-9999px;" alt="" /></div></noscript>`,
    );

    (function (m, e, t, r, i, k, a) {
      // @ts-expect-error
      m[i] =
        // @ts-expect-error
        m[i] ||
        function () {
          // @ts-expect-error
          // eslint-disable-next-line prefer-rest-params
          (m[i].a = m[i].a || []).push(arguments);
        };
      // @ts-expect-error
      m[i].l = 1 * new Date();
      // @ts-expect-error
      // eslint-disable-next-line no-unused-expressions,no-sequences
      (k = e.createElement(t)),
        // @ts-expect-error
        (a = e.getElementsByTagName(t)[0]),
        // @ts-expect-error
        (k.async = 1),
        // @ts-expect-error
        (k.src = r),
        // @ts-expect-error
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    // @ts-expect-error
    ym(runtimeConfig.public.yandexId, 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      trackHash: true,
      params: { auid: useCookie('auid').value },
    });

    router.isReady().then(() => {
      router.afterEach((to, from) => {
        try {
          // @ts-expect-error
          ym(runtimeConfig.public.yandexId, 'hit', basePath + to.fullPath, {
            referer: basePath + from.fullPath,
            title: to.meta.title,
          });
        } catch {}
      });
    });
  }

  onNuxtReady(() => {
    if (config.featureFlags?.yandex) {
      loadYandex();
    }
  });
});
