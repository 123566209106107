import { chunk, get, random, set, unset } from 'lodash-es';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import {
  getTokens,
  resetTokens,
  setTokens,
  injectAuthorizationHeader,
  isTokenValid,
} from '../composables/service/auth';
import { ExpiredAuthSessionError } from '../utils/errors';
import { callWithNuxt } from '#app';
import {
  defineNuxtPlugin,
  useRuntimeConfig,
  useAuthService,
  useAirpazCookie,
  useTrafficCookie,
  useTrafficSession,
  useNuxtApp,
  defineTrafficSource,
  makeid,
  safeBtoa,
  generateRandomString,
} from '#imports';

const httpFatalErrorMap: {
  [path: string]: number[];
} = {
  '/flight/livecrawl': [422],
  '/flight/search': [422],
  '/flight/livecrawl-monitor': [422],
  '/flight/livecrawl-roundtrip': [422],
  '/flight/submit': [422],
  '/flight/order': [422],
  '/payment/bank-options': [422],
  '/payment/submit': [422],
  '/payment/checkout': [422],
};

const ignoredErrorIdMap: { [path: string]: Array<{ status: number; id: string }> } = {
  '/flight/form-config': [{ status: 410, id: 'DATA_EXPIRED' }],
  '/flight/submit': [{ status: 422, id: 'STALE_FLIGHT_DATA' }],
  '/flight/livecrawl': [
    { status: 400, id: 'BAD_REQUEST' },
    { status: 422, id: 'INVALID_AIRPORT' },
    { status: 403, id: 'FORBIDDEN' },
  ],
  '/book/get': [{ status: 404, id: 'TRX_NOT_FOUND' }],
  '/member/verify-activation-code': [{ status: 422, id: 'INVALID_ACTIVATION_CODE' }],
  '/member/reset-pass/request': [{ status: 422, id: 'UNVERIFIED_ACCOUNT' }],
};

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const nuxtApp = useNuxtApp();
  const router = useRouter();
  const coords = useAirpazCookie('usr_coords');
  const auid = useAUID();
  const sessionId = useSessionId();
  const asnNum = useAsnNum();
  const trafficCookie = useTrafficCookie();
  const trafficSession = useTrafficSession();
  const hs = import.meta.server
    ? useRequestEvent().context.hs
    : // @ts-expect-error
      window.ItsfUBwMJcc?.split(',').join('');

  const apifront = $fetch.create({
    baseURL: import.meta.server ? config.public.apiBaseServer : config.public.apiBase,
    async onRequest({ options }) {
      const route = router.currentRoute.value;

      if (options.v2) {
        options.baseURL = options.baseURL?.replace('v1', 'v2');
      }

      if (options.params?.lang === 'zh-tw') {
        options.params.lang = 'zh-TW';
      }

      // @ts-expect-error
      if (options.body?.lang === 'zh-tw') {
        // @ts-expect-error
        options.body.lang = 'zh-TW';
      }

      const cookieTrafficSource = defineTrafficSource(trafficCookie.ads.value, trafficCookie.affiliate.value);
      const sessionTrafficSource = defineTrafficSource(trafficSession.ads.value, trafficSession.affiliate.value);
      const encodedSourcePar = safeBtoa(JSON.stringify(cookieTrafficSource.sourcePar));

      if (import.meta.server && nuxtApp.$device.userAgent) {
        set(options, ['headers', 'user-agent'], nuxtApp.$device.userAgent);
      }

      if (coords.value) {
        set(options, ['headers', 'x-coords'], coords.value);
      }

      if (auid.value && !get(options, ['headers', 'x-auid'])) {
        set(options, ['headers', 'x-auid'], auid.value);
      }

      if (route.query.aft) {
        set(options, ['headers', 'x-aft'], route.query.aft);
      } else {
        unset(options, ['headers', 'x-aft']);
      }

      if (cookieTrafficSource.source) {
        set(options, ['headers', 'x-source'], cookieTrafficSource.source);
      }

      if (encodedSourcePar) {
        set(options, ['headers', 'x-sourcepar'], encodedSourcePar);
      }

      if (import.meta.client) {
        if (sessionId.value) {
          set(options, ['headers', 'x-session-id'], sessionId.value);
        }

        set(options, ['headers', 'x-ref-src'], sessionTrafficSource.source || 'DIRECT');
      }

      try {
        set(options, ['headers', 'x-trace-id'], nanoid(10));
      } catch (_e) {
        set(options, ['headers', 'x-trace-id'], makeid(10));
      }

      if (options.withCSRF) {
        try {
          const { result } = await apifront<{ result: string }>('/csrf');

          if (result) {
            set(options, ['headers', 'x-csrf-token'], result);
          } else {
            unset(options, ['headers', 'x-csrf-token']);
          }
        } catch (_e) {}
      }

      if (hs) {
        let customKey = '';

        if (
          typeof route.query.depAirport === 'string' &&
          typeof route.query.arrAirport === 'string' &&
          typeof route.query.depDate === 'string'
        ) {
          const slug =
            route.query.depAirport.substring(0, 3) +
            route.query.arrAirport.substring(0, 3) +
            dayjs(route.query.depDate).locale('en').format('DMMMYYYY');

          const regex = /.{1,3}/g;
          const charGroups = Array.from({ length: 3 }, (_, index) => slug.toLowerCase().slice(index).match(regex));

          customKey = charGroups
            ?.map((group) =>
              group
                ?.map((group) => group[0])
                .join('')
                .slice(0, 3),
            )
            .join('');
        }

        const cs = import.meta.server || !customKey ? '' : chunk(customKey, 3)[random(0, 2)]?.join('') ?? '';

        set(
          options,
          ['headers', 'x-kn'],
          safeBtoa(
            generateRandomString(3, 5) + atob(hs) + generateRandomString(2, 7) + cs + generateRandomString(1, 3),
          ),
        );
      }

      if (asnNum.value) {
        set(options, ['headers', 'x-asn-num'], asnNum.value);
      }

      if (options.checkAuth) {
        const { token, tokenExpiration, refreshToken, refreshTokenExpiration } = await getTokens(nuxtApp);

        if (!isTokenValid(token.value, Number(tokenExpiration.value))) {
          // @ts-expect-error
          if (!token.value && !!options.headers?.Authorization) {
            throw new ExpiredAuthSessionError('No token, has authorization header');
          }

          if (!isTokenValid(refreshToken.value, Number(refreshTokenExpiration.value))) {
            await resetTokens(nuxtApp);

            if (!refreshToken.value) {
              return;
            }

            throw new ExpiredAuthSessionError(!refreshToken.value ? 'Invalid refresh token' : 'Expired refresh token', {
              tokenExpiration,
              refreshTokenExpiration,
            });
          }

          const { result } = await callWithNuxt(nuxtApp, () => useAuthService().refreshToken(refreshToken.value!));

          await setTokens(result.token, result.refreshToken, nuxtApp);

          injectAuthorizationHeader(options, 'Bearer ' + result.token);

          return;
        }

        injectAuthorizationHeader(options, token.value!);
      }
    },
    onResponseError({ request, response, options }) {
      const route = router.currentRoute.value;
      const path = new URL(typeof request === 'string' ? request : '').pathname;
      const pathWithoutVersion = path.replace(/^\/v\d/gi, '');

      if (
        nuxtApp.$sentry &&
        ![401, 409].includes(response.status) &&
        !ignoredErrorIdMap[pathWithoutVersion]?.some(
          (error) => error.status === response.status && error.id === response._data.error,
        )
      ) {
        const message = `HTTP ${response.status} ${path}`;

        const isFatal = response.status > 499 || httpFatalErrorMap[pathWithoutVersion]?.includes(response.status);

        nuxtApp.$sentry.captureEvent({
          level: isFatal ? 'fatal' : 'error',
          tags: {
            apifront_error: typeof response._data?.error === 'string' ? response._data?.error : undefined,
          },
          exception: {
            values: [
              {
                type: 'HttpError',
                value: message,
              },
            ],
          },
          request: {
            url: typeof request === 'string' ? request : undefined,
            method: options.method,
            headers: options.headers as any,
          },
          contexts: {
            route: {
              name: route.name,
              fullPath: route.fullPath,
              path: route.path,
              params: route.params,
              query: route.query,
            },
            request: {
              params: JSON.stringify(options.body ?? options.params),
            },
            response: {
              status_code: response.status,
              status_text: response._data?.message || response.statusText,
              error_id:
                typeof response._data?.error === 'string'
                  ? response._data?.error
                  : JSON.stringify(response._data?.error),
            },
          },
        });
      }
    },
  });

  return {
    provide: {
      apifront,
    },
  };
});

declare module 'ofetch' {
  interface FetchOptions {
    withCSRF?: boolean;
    checkAuth?: boolean;
    v2?: boolean;
  }
}
