<script lang="ts" setup>
import { computed } from 'vue';

export type faIconType = 'fal' | 'far' | 'fas' | 'fab' | 'fak';

export interface IIconProps {
  icon?: string | [faIconType, string];
  fixedWidth?: boolean;
  size?: 'lg' | 'xs' | 'sm' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x';
}

const props = defineProps<IIconProps>();

const iconName = computed(() => {
  let name = '';
  if (!props.icon) {
    name = 'ai:question';
  } else if (Array.isArray(props.icon)) {
    name = 'ai:' + props.icon[1];
  } else {
    name = 'ai:' + props.icon;
  }

  if (name === 'ai:twitter') {
    name = 'ai:x';
  } else if (name === 'ai:angle-down') {
    name = 'ai:chevron-down';
  } else if (name === 'ai:angle-right') {
    name = 'ai:chevron-right';
  } else if (name === 'ai:angle-left') {
    name = 'ai:chevron-left';
  } else if (name === 'ai:lock') {
    name = 'ai:lock-alt';
  } else if (name === 'ai:lock-open') {
    name = 'ai:lock-open-alt';
  } else if (name === 'ai:pencil' || name === 'ai:pencil-alt') {
    name = 'ai:pen';
  }

  return name;
});

const iconClasses = computed(() => {
  const classes = ['icon'];

  if (props.size) {
    classes.push('icon-' + props.size);
  }

  if (props.fixedWidth) {
    classes.push('icon-fw');
  }

  return classes;
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <iconify-icon
    :icon="iconName"
    :class="iconClasses"
    v-bind="$attrs"
  ></iconify-icon>
</template>

<style lang="scss" scoped>
@use 'sass:math';

.icon {
  @apply inline-block;

  width: 1em;
  height: 1em;
  vertical-align: -0.125em;

  &-fw::before {
    width: math.div(20em, 16);
  }

  &-lg {
    font-size: math.div(4em, 3) !important;
    line-height: (3em * 0.25);
    vertical-align: -0.0667em;
  }

  &-xs {
    font-size: 0.75em !important;
  }

  &-sm {
    font-size: 0.875em !important;
  }

  @for $i from 1 through 10 {
    &-#{$i}x {
      font-size: $i * 1em !important;
    }
  }
}
</style>
