import { RouteLocationRaw } from 'vue-router';
import { navigateTo, useNuxtApp, useLocaleRoute, removeTrailingSlash } from '#imports';

export const useNavigation = () => {
  const { $i18n } = useNuxtApp();
  const localeRoute = useLocaleRoute();

  function getPath(path: string) {
    if (!path.startsWith(`/${$i18n.locale.value}`)) {
      path = `/${$i18n.locale.value}` + path;
    }

    return path;
  }

  function navigationPath(to: RouteLocationRaw) {
    const route = localeRoute(to);

    if (route && !route.name) {
      return removeTrailingSlash(getPath(route.fullPath));
    }

    return route ? removeTrailingSlash(route.fullPath) : getPath('/');
  }

  function navigate(
    to: RouteLocationRaw,
    options?: {
      replace?: boolean;
      redirectCode?: number;
      external?: boolean;
      externalAirpaz?: boolean;
      open?: { target: string };
    },
  ) {
    const route = localeRoute(to);

    if (route) {
      if (options?.externalAirpaz) {
        const path = removeTrailingSlash(getPath(route.fullPath));

        if (options.open) {
          window.open(path, options.open.target);
        } else if (options.replace) {
          window.location.replace(path);
        } else {
          window.location.href = path;
        }

        return Promise.resolve();
      }

      if (!route.name) {
        return navigateTo(removeTrailingSlash(getPath(route.fullPath)), {
          ...options,
          external: true,
        });
      }

      return navigateTo(removeTrailingSlash(route.fullPath));
    }

    return navigateTo(getPath('/'), options);
  }

  return { navigate, navigationPath };
};
