import validate from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_sass@1.69.7_typescript@5.3.3_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/apzfront/forerunner-next/node_modules/.pnpm/nuxt@3.9.3_patch_hash=i4ymrhr7d7txtoye7xwhuenzym_@types+node@20.10.6_sass@1.69.7_typescript@5.3.3_vite@5.0.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  campaign: () => import("/builds/apzfront/forerunner-next/apps/forerunner/middleware/campaign.ts"),
  "flight-result": () => import("/builds/apzfront/forerunner-next/apps/forerunner/middleware/flight-result.ts"),
  "hotel-result": () => import("/builds/apzfront/forerunner-next/apps/forerunner/middleware/hotel-result.ts")
}