import * as Sentry from '@sentry/vue';
import { Pinia } from 'pinia';
import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { useAuth } from '#imports';

export default defineNuxtPlugin(() => {
  const nuxtApp = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const appConfig = useAppConfig();
  const auth = useAuth();
  const config = useConfigStore(nuxtApp.$pinia as Pinia);

  const trafficCookie = useTrafficCookie();

  Sentry.init({
    enabled: runtimeConfig.public.sentry.enabled,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: runtimeConfig.public.sentry.enableDebug,
    dsn: runtimeConfig.public.sentry.dsn,
    environment: runtimeConfig.public.sentry.environment,
    release: appConfig.projectName + '-next@release-' + appConfig.projectVersion,
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: runtimeConfig.public.sentry.enableInp,
        router: nuxtApp.$router,
      }),
      Sentry.extraErrorDataIntegration(),
    ],
    trackComponents: true,
    sendDefaultPii: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],

    tracesSampler({ transactionContext }) {
      if (['index', 'hotel-slug___en', 'flight-code'].some((name) => transactionContext?.name?.startsWith(name))) {
        return 0.05;
      }

      if (['airlines-slug', 'hotel-slug'].some((name) => transactionContext?.name?.startsWith(name))) {
        return 0.02;
      }

      if (['callback'].some((name) => transactionContext?.name?.startsWith(name))) {
        return 0;
      }

      return runtimeConfig.public.sentry.tracesSampleRate;
    },
    replaysSessionSampleRate: runtimeConfig.public.sentry.replaysSessionSampleRate,
    replaysOnErrorSampleRate: runtimeConfig.public.sentry.replaysOnErrorSampleRate,

    ignoreErrors: [
      // temp ignore failed import module error as nuxt already handle it by auto refresh the page when it happens
      'Failed to fetch dynamically imported module',
      'error loading dynamically imported module',
      'Importing a module script failed.',
      'Unable to preload CSS ',

      // temp ignore as fetch API is currently not stable in safari iOS
      'Load failed (https://next-api.airpaz.com/v1',

      /** @TODO find solution */
      'Invalid array length',
      "Cannot read property 'subTree' of null",
      'Array size is not a small enough positive integer.',
      `Cannot read properties of null (reading 'subTree')`,
      'Failed to fetch',
      'Fetch is aborted',
      'FetchError: (422',
      'FetchError',
      'AbortError',
      'NetworkError',
      "Unexpected token '='",
      'null is not an object',
      'Load failed',
      '<unknown>',
      'ResizeObserver',
      'undefined is not an object (evaluating',
      "Can't find variable: zaloJSV2",

      // https://github.com/getsentry/sentry-javascript/issues/3440
      'Non-Error promise rejection captured',

      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
    ],

    allowUrls: ['https://www.airpaz.com', 'https://beta.airpaz.com', 'https://new.airpaz.com'],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });

  Sentry.setUser({ id: useAUID().value || undefined });

  Sentry.setTags({
    'user.type': auth.loggedIn ? 'member' : auth.user?.isAgent ? 'agent' : 'guest',
    currency: config.currency,
    country: config.country,
    language: nuxtApp.$i18n.locale.value,
    traffic_source: defineTrafficSource(trafficCookie.ads.value, trafficCookie.affiliate.value).source || 'DIRECT',
  });

  return {
    provide: {
      sentry: {
        captureException: Sentry.captureException,
        captureMessage: Sentry.captureMessage,
        captureEvent: Sentry.captureEvent,
        withScope: Sentry.withScope,
        setUser: Sentry.setUser,
        addBreadcrumb: Sentry.addBreadcrumb,
      },
    },
  };
});
