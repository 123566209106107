import type { EventBusKey } from '@vueuse/core';

export type ToastPayload = {
  message: string;
  id?: number;
  type?: 'success' | 'error' | 'warning' | 'info';
  duration?: number;
  group?: string;
  compact?: boolean;
};

export const toastEventBusyKey: EventBusKey<{ name: 'open'; payload: ToastPayload }> = Symbol('toast');
