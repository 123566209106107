import { joinURL, encodeQueryItem } from 'ufo';
import { ProviderGetImage } from '@nuxt/image';
import { createOperationsGenerator } from '#image';

const operationsGenerator = createOperationsGenerator({
  keyMap: {
    width: 'w',
    height: 'h',
    dpr: 'dpr',
    fit: 'fit',
    gravity: 'g',
    quality: 'q',
    format: 'f',
    sharpen: 'sharpen',
  },
  valueMap: {
    fit: {
      cover: 'cover',
      contain: 'contain',
      fill: 'scale-down',
      outside: 'crop',
      inside: 'pad',
    },
    gravity: {
      auto: 'auto',
      side: 'side',
    },
  },
  joinWith: ',',
  formatter: (key: string, val: string) => encodeQueryItem(key, val),
});

const defaultModifiers = {};

const urlWhitelist = [
  'https://cdn.airpaz.com',
  'https://static.airpaz.com',
  'https://lbcdn.airpaz.com',
  'https://www.airpaz.com',
];

// https://developers.cloudflare.com/images/image-resizing/url-format/
export const getImage: ProviderGetImage = (
  src,
  { modifiers = {}, baseURL = 'https://cdn.airpaz.com', disabled } = {},
) => {
  if (process.dev) {
    return { url: src };
  }

  if (src?.startsWith('/img')) {
    src = joinURL(baseURL, 'forerunner-next', src);
  }

  const matchedWhitelistedUrl = urlWhitelist.find((url) => src?.startsWith(url));

  if (!matchedWhitelistedUrl) {
    return { url: src };
  }

  const mergeModifiers = { ...defaultModifiers, ...modifiers };
  const operations = operationsGenerator(mergeModifiers as any);

  src = src.replace(matchedWhitelistedUrl, '');

  // https://<ZONE>/cdn-cgi/image/<OPTIONS>/<SOURCE-IMAGE>
  const url =
    operations && !disabled
      ? joinURL(matchedWhitelistedUrl, 'cdn-cgi/image', operations, src)
      : joinURL(matchedWhitelistedUrl, src);

  return {
    url,
  };
};
