<script lang="ts" setup>
import { computed } from 'vue';

const SIZE = {
  small: 15,
  medium: 30,
  large: 45,
};

interface Props {
  type?: 'bounce' | 'circle';
  size?: keyof typeof SIZE;
}

const props = withDefaults(defineProps<Props>(), {
  type: 'bounce',
  size: 'medium',
});

const bounceParent = computed(() => {
  return SIZE[props.size] * 3 + 'px';
});

const bounceChild = computed(() => {
  return SIZE[props.size] / 2 + 'px';
});

const circleParent = computed(() => {
  return SIZE[props.size] + 'px';
});
</script>

<template>
  <div class="loader">
    <div
      v-if="type === 'bounce'"
      class="sk-three-bounce"
      :style="{ width: bounceParent }"
    >
      <div
        class="sk-child sk-bounce1"
        :style="{ width: bounceChild, height: bounceChild }"
      />
      <div
        class="sk-child sk-bounce2"
        :style="{ width: bounceChild, height: bounceChild }"
      />
      <div
        class="sk-child sk-bounce3"
        :style="{ width: bounceChild, height: bounceChild }"
      />
    </div>

    <div
      v-if="type === 'circle'"
      class="sk-fading-circle"
      :style="{ width: circleParent, height: circleParent }"
    >
      <div class="sk-circle1 sk-circle" />
      <div class="sk-circle2 sk-circle" />
      <div class="sk-circle3 sk-circle" />
      <div class="sk-circle4 sk-circle" />
      <div class="sk-circle5 sk-circle" />
      <div class="sk-circle6 sk-circle" />
      <div class="sk-circle7 sk-circle" />
      <div class="sk-circle8 sk-circle" />
      <div class="sk-circle9 sk-circle" />
      <div class="sk-circle10 sk-circle" />
      <div class="sk-circle11 sk-circle" />
      <div class="sk-circle12 sk-circle" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:math';

$spinkit-spinner-margin: 0 auto !default;
$spinkit-size: 20px !default;
$spinkit-spinner-color: currentColor !default;

.sk-three-bounce {
  $animation-duration: 1.4s;
  $delay-range: 0.32s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size * 2;
  text-align: center;

  .sk-child {
    width: $spinkit-size * 0.5;
    height: $spinkit-size * 0.5;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    display: inline-block;
    animation: sk-three-bounce $animation-duration ease-in-out 0s infinite both;
  }

  .sk-bounce1 {
    animation-delay: -$delay-range;
  }
  .sk-bounce2 {
    animation-delay: -$delay-range * 0.5;
  }
}

@keyframes sk-three-bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.sk-fading-circle {
  $circle-count: 12;
  $animation-duration: 1.2s;

  margin: $spinkit-spinner-margin;
  width: $spinkit-size;
  height: $spinkit-size;
  position: relative;

  .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .sk-circle::before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $spinkit-spinner-color;
    border-radius: 100%;
    animation: sk-circleFadeDelay $animation-duration infinite ease-in-out both;
  }

  @for $i from 2 through $circle-count {
    .sk-circle#{$i} {
      transform: rotate(math.div(360deg, $circle-count) * ($i - 1));
    }
  }

  @for $i from 2 through $circle-count {
    .sk-circle#{$i}:before {
      animation-delay: -$animation-duration + math.div($animation-duration, $circle-count) * ($i - 1);
    }
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
</style>
