import { v4 as uuid4 } from 'uuid';
import dayjs from 'dayjs';

export default defineNuxtPlugin(() => {
  const auid = useAirpazCookie('auid', { expires: dayjs().add(10, 'year').toDate(), default: () => uuid4() });
  const sessionId = useSessionStorage('sessionId', uuid4());

  useAUID().value = auid.value;
  useSessionId().value = sessionId.value;
});
