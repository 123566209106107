<script setup lang="ts">
import type { NuxtError } from '#app';
import { omit } from 'lodash-es';
import { computed, useI18n, useHead, useNavigation, clearError, navigateSignIn } from '#imports';
// prevent reactive update when clearing error

const { error } = defineProps<{
  error: Partial<NuxtError>;
}>();

const { t } = useI18n();
const { navigationPath } = useNavigation();

const debugMode = useAirpazCookie('debug_mode');

if (error) {
  if (process.dev) {
    console.error('[ERROR PAGE]', error);
  }

  if (typeof error.statusCode === 'number' && [401, 403].includes(error.statusCode)) {
    await navigateSignIn();
  }
}

useHead({
  title: t('global.somethingwrong'),
});

const errorConfig = computed(() => {
  // @ts-expect-error
  if (error.data?.error) {
    // @ts-expect-error
    switch (error.data.error as string) {
      case 'TRX_NOT_FOUND':
      case 'TRX_MEMBER_NOT_FOUND':
        return {
          title: t('result.ordernofound'),
          description: t('result.ordernofounddesc'),
          redirect: {
            text: t('orders.findorder'),
            path: navigationPath('/order'),
          },
        };
      default:
        return {
          title: t('global.somethingwrong'),
          description: t('global.somethingwrongdesc'),
        };
    }
  }

  switch (error.statusCode) {
    case 404:
      return {
        title: t('e404.pagenotfound'),
        description: t('e404.message1'),
      };
    default:
      return {
        title: t('global.somethingwrong'),
        description: t('global.somethingwrongdesc'),
      };
  }
});

const errorIllustration = computed(() => {
  return (
    {
      404: '/img/illustration/notfound_2x.png',
    }[error.statusCode!] ?? '/img/illustration/error_alt_2x.png'
  );
});

const handleError = () => clearError({ redirect: navigationPath('/') });
</script>

<template>
  <div class="h-screen flex flex-col bg-white py-50">
    <div class="flex items-center flex-1">
      <div class="container flex flex-col md:flex-row items-center justify-between">
        <div class="flex flex-col items-center md:items-start max-w-lg order-last md:order-none">
          <p class="text-extra text-gray-dark hidden md:block">Error: {{ error.statusCode }}</p>
          <h1 class="text-medium sm:text-ultra text-center sm:text-left font-bold mt-20">
            {{ errorConfig.title }}
          </h1>
          <p
            v-if="errorConfig.description"
            class="text-normal sm:text-medium text-center sm:text-left text-gray-dark mt-10 sm:mt-20"
          >
            {{ errorConfig.description }}
          </p>

          <ApzButton
            v-if="errorConfig.redirect"
            type="primary"
            tag="nuxt-link"
            class="mt-30"
            :to="errorConfig.redirect.path"
            external
            no-rel
          >
            {{ errorConfig.redirect.text }}
          </ApzButton>

          <ApzButton
            v-else
            type="primary"
            class="mt-30"
            @click="handleError"
          >
            {{ $t('global.backtohome') }}
          </ApzButton>
        </div>

        <NuxtImg
          :src="errorIllustration"
          width="600"
        />
      </div>
    </div>

    <div
      v-if="error && debugMode"
      class="bg-whiter p-20 mx-20 rounded overflow-x-auto shadow"
    >
      <pre>{{ omit(error, 'stack') }}</pre>

      <div
        v-if="error.stack"
        class="text-normal mt-10"
        v-html="error.stack"
      />
    </div>
  </div>
</template>
