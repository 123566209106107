import dayjs from 'dayjs';
import { LOCALE_DATE_FORMAT } from '@airpaz/apz-js';

import { defineNuxtPlugin, useNuxtApp } from '#app';

export default defineNuxtPlugin(async () => {
  const nuxtApp = useNuxtApp();
  const dayjsConfig = (await import(`../config/dayjs/${nuxtApp.$i18n.locale.value}.ts`)).default;

  dayjs.locale(dayjsConfig, undefined);

  return {
    provide: {
      dt(date: dayjs.ConfigType, toFormat = 'LLL', fromFormat?: dayjs.OptionType) {
        const format =
          dayjsConfig?.formats[toFormat] ??
          toFormat ??
          LOCALE_DATE_FORMAT[nuxtApp.$i18n.locale.value as keyof typeof LOCALE_DATE_FORMAT];

        return dayjs(date, fromFormat).locale(dayjsConfig).format(format);
      },
    },
  };
});
