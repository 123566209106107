import { deburr, isEmpty } from 'lodash-es';

export const isUnset = (o: any) => typeof o === 'undefined' || o === null;
export const isSet = (o: any) => !isUnset(o);

// export const isEmpty = (obj: any) =>
//   [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length;

// export const pick = (object: object, keys: string[]) => {
//   return keys.reduce((obj, key) => {
//     // @ts-expect-error
//     if (object && object[key]) {
//       // @ts-expect-error
//       obj[key] = object[key];
//     }
//     return obj;
//   }, {});
// };

export const hasNonLatin = (str: string) =>
  // eslint-disable-next-line no-control-regex
  isSet(str) && /[^\u0000-\u007F]/gi.test(str);

export const trim = (str: string) => {
  if (typeof str === 'string') {
    return str.replace(/\s+/g, ' ').trim();
  }

  return '';
};

export const combineStrings = (strings: string[], separator = ', ') => {
  if (!Array.isArray(strings)) {
    return '';
  }

  return strings.filter(Boolean).join(`${separator}`);
};

/**
 * Handles arithmetic operation on float numbers
 * @param  {...numbers} addends
 */
export const fixRounding = (value: number) => Math.round(value * 100) / 100;

const _safeArithmetic = (values: number[], fn: (acc: number, value: number) => number) =>
  (!isEmpty(values) && values.filter(isSet).reduce((acc, value) => fixRounding(fn(Number(acc), Number(value))))) || 0;

export const add = (...addends: number[]) => _safeArithmetic(addends, (acc, value) => acc + value);

export const subtract = (...subtrahends: number[]) => _safeArithmetic(subtrahends, (acc, value) => acc - value);

export const multiply = (...multiplicands: number[]) => _safeArithmetic(multiplicands, (acc, value) => acc * value);

export const divide = (...dividens: number[]) => _safeArithmetic(dividens, (acc, value) => acc / value);

export const isStringEqual = (value: string | undefined, other: string | undefined) => {
  if (isUnset(value) || isUnset(other)) {
    return false;
  }
  return `${value}`.toLowerCase() === `${other}`.toLowerCase();
};

export const normalizeSlug = (slug: string, lowercase?: boolean) =>
  deburr(lowercase ? slug.toLowerCase() : slug)
    .replace(/\s*([^a-z0-9\u4E00-\u9FAF\u3040-\u309F\u30A0-\u30FF\u3131-\uD79D\u0E00-\u0E7F\u0600-\u06FF]+)\s*/gi, '-')
    .replace(/^-+|\s|\.|\\|\/|'/gi, '');

export const stringifyQuery = (query: { [key: string]: string }) => {
  let queryStr = '';

  Object.entries(query).forEach(([key, value], index) => {
    if (index > 0) queryStr += '&';

    queryStr += `${key}=${value}`;
  });

  return queryStr;
};

export const parseQuery = <T = any>(queryString: string) => {
  const query = {} as T;
  const pairs = queryString.trim().split('&').filter(Boolean);

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i]!.split('=');
    // @ts-expect-error
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
};

export const safelyParseJSON = <T = unknown>(str: string) => {
  let res = null;

  try {
    res = JSON.parse(str);
  } catch {}

  return (res ?? null) as T | null;
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const compose =
  (...fns: any[]) =>
  (x: any) =>
    fns.reduceRight((res, fn) => fn(res), x);

export const makeid = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const safeAtob = (data: string) => {
  try {
    return atob(data);
  } catch (error) {
    return '';
  }
};

export const safeBtoa = (data: string) => {
  try {
    return btoa(data);
  } catch (error) {
    return '';
  }
};

export const removeZeroWidthSpace = (str: string) => str.replace(/[\u200B-\u200D\uFEFF]/g, '');

export const removeTrailingSlash = (path: string) => path.replace(/\/+$/, '');

export const renameKeys = <T>(keysMap: Record<string, string>, obj: Object) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key as keyof typeof obj] },
    }),
    {} as T,
  );

export const generateRandomString = (minLength: number, maxLength: number, alphanum = true) => {
  let alphaNumChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  if (alphanum) {
    alphaNumChars += '0123456789';
  }

  let result = '';
  const charactersLength = alphaNumChars.length;
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  for (let i = 0; i < length; i++) {
    result += alphaNumChars.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
