import { CookieOptions } from '#app';

export const useAirpazCookie = <T = string | null>(
  name: string,
  opts?: CookieOptions<T> & {
    readonly?: false;
  },
) =>
  useCookie<T>(name, {
    ...opts,
    domain: useRuntimeConfig().public.cookieDomain,
  });
