import { get } from 'lodash-es';

export class ExpiredAuthSessionError extends Error {
  details: object | null = null;

  constructor(reason = '', details?: object) {
    super('Auth token or refresh token is invalid. Your request was aborted.' + (reason ? ` Reason: ${reason}` : ''));
    this.name = 'ExpiredAuthSessionError';

    if (details) {
      this.details = details;
    }
  }
}

export const getApiErrorMessage = (error: unknown, fallbackMessage: string | null = null) => {
  return get(error, 'data.message', get(error, 'message', fallbackMessage));
};

export const getApiErrorCode = (error: unknown) => {
  return get(error, 'data.error', get(error, 'error', null)) as string | null;
};
