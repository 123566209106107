<script setup lang="ts">
import { computed, onMounted } from '#imports';

interface IProps {
  message: string;
  id?: number;
  type?: 'success' | 'error' | 'warning' | 'info' | 'default';
  duration?: number;
  compact?: boolean;
}
interface IEmits {
  (e: 'close', id?: number): void;
}

const props = withDefaults(defineProps<IProps>(), {
  type: 'default',
  duration: 3000,
});
const emit = defineEmits<IEmits>();

const typeClasses = computed(() => {
  const types = {
    success: 'bg-success text-white',
    error: 'bg-danger text-white',
    warning: 'bg-warning text-white',
    info: 'bg-info text-white',
  };

  return types[props.type as keyof typeof types] ?? 'bg-white text-gray-darkest';
});

const icon = computed(() => {
  const icons = {
    success: 'check-circle',
    error: 'times-circle',
    warning: 'exclamation-circle',
    info: 'info-circle',
  };

  return icons[props.type as keyof typeof icons];
});

onMounted(() => {
  if (props.duration) {
    setTimeout(() => {
      emit('close', props.id);
    }, props.duration);
  }
});
</script>

<template>
  <div
    class="flex items-center font-bold rounded shadow-lg mb-10"
    :class="[typeClasses, compact ? 'text-small p-15' : 'text-base p-20']"
  >
    <ApzIcon
      v-if="!compact && icon"
      :icon="icon"
      class="text-[20px] mr-15"
    />

    <div>{{ props.message }}</div>
  </div>
</template>
