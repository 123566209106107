<script lang="ts" setup>
import { InputHTMLAttributes } from 'vue';
import { IIconProps } from '../ApzIcon/ApzIcon.vue';
import { computed, useAttrs, useSlots, resolveComponent } from '#imports';

export interface IButtonProps extends /* @vue-ignore */ InputHTMLAttributes {
  loading?: boolean;
  icon?: IIconProps['icon'];
  iconRight?: boolean;
  nativeType?: 'button' | 'submit' | 'reset';
  size?: 'default' | 'small' | 'large';
  type?: 'default' | 'primary' | 'primary-gradient' | 'secondary';
  variant?: 'default' | 'outline' | 'clear' | 'link';
  tag?: 'button' | 'a' | 'input' | 'nuxt-link' | 'div';
}

const props = withDefaults(defineProps<IButtonProps>(), {
  nativeType: 'button',
  size: 'default',
  type: 'default',
  variant: 'default',
  tag: 'button',
});

const attrs = useAttrs();
const slots = useSlots();

const variantClasses = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return '!bg-gray-lightest !text-gray';
  }

  const classes = [];

  const variants = {
    outline: '!bg-transparent border',
    clear: '!bg-transparent',
    link: '!bg-transparent !ring-0 !ring-offset-0 !border-none underline-offset-2 hover:underline',
    default: '',
  };

  classes.push(variants[props.variant] ?? variants.default);

  if (props.variant === 'outline') {
    const types = {
      primary: 'border-primary !text-primary hover:!bg-primary hover:!text-white',
      secondary: 'border-secondary !text-secondary hover:!bg-secondary hover:!text-white',
      default: 'hover:!bg-whiter',
    };

    // @ts-expect-error
    classes.push(types[props.type] ?? types.default);
  } else if (props.variant === 'clear') {
    const types = {
      primary: 'border-primary !text-primary hover:!bg-primary hover:!bg-opacity-25',
      secondary: 'border-secondary !text-secondary hover:!bg-secondary hover:!bg-opacity-25',
      default: 'hover:!bg-whiter',
    };

    // @ts-expect-error
    classes.push(types[props.type] ?? types.default);
  } else if (props.variant === 'link') {
    const types = {
      primary: '!text-primary',
      secondary: '!text-secondary',
    };

    // @ts-expect-error
    classes.push(types[props.type] ?? types.default);
  }

  return classes;
});

const sizeClasses = computed(() => {
  const sizes = {
    small: 'h-[35px] text-small',
    large: 'h-[54px] text-base',
    default: 'h-[49px] text-base',
  };

  return sizes[props.size] ?? sizes.default;
});

const typeClasses = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'cursor-default';
  }

  const types = {
    primary: 'bg-primary text-white hover:bg-primary-dark active:bg-primary-dark focus:ring-primary',
    'primary-gradient':
      'bg-gradient-to-r from-primary to-primary-dark text-white hover:bg-primary-dark active:bg-primary-dark focus:ring-primary',
    secondary: 'bg-secondary text-white hover:bg-secondary-dark active:bg-secondary-dark focus:ring-secondary',
    default: 'bg-whiter hover:bg-gray-lightest active:bg-gray-lightest focus:ring-gray-lightest',
  };

  return types[props.type] ?? types.default;
});

const computedTag = computed(() => {
  if (attrs.disabled !== undefined && attrs.disabled !== false) {
    return 'button';
  }

  if (props.tag === 'nuxt-link') {
    return resolveComponent('NuxtLink');
  }

  return props.tag;
});

const computedIcon = computed(() => {
  return typeof props.icon === 'string' ? (['fal', props.icon] as IIconProps['icon']) : props.icon;
});

const hasLeftIcon = computed(() => {
  return (props.icon && !props.iconRight) || slots['icon-left'];
});

const hasRightIcon = computed(() => {
  return (props.icon && props.iconRight) || slots['icon-right'];
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <component
    :is="computedTag"
    class="flex items-center justify-center px-15 rounded font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-opacity-50"
    :class="[typeClasses, sizeClasses, variantClasses]"
    v-bind="$attrs"
    :type="nativeType"
    :aria-disabled="$attrs.disabled"
  >
    <ApzLoading
      v-if="loading"
      type="circle"
      light
    />

    <template v-else>
      <slot
        v-if="hasLeftIcon"
        name="icon-left"
      >
        <ApzIcon
          :icon="computedIcon!"
          class="pointer-events-none mr-5 icon-left"
        />
      </slot>

      <slot />

      <slot
        v-if="hasRightIcon"
        name="icon-right"
      >
        <ApzIcon
          :icon="computedIcon!"
          class="pointer-events-none ml-5 icon-right"
        />
      </slot>
    </template>
  </component>
</template>
