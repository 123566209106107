
import * as apzCDNRuntime$Zk9jMJEooG from '/builds/apzfront/forerunner-next/packages/base-nuxt-layer/config/image-provider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "apzCDN",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['apzCDN']: { provider: apzCDNRuntime$Zk9jMJEooG, defaults: {"baseURL":"https://cdn.airpaz.com","disabled":false} }
}
        